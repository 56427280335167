/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Box from '@mui/material/Box';

const NoticeToVacateInstructions = () => {
  return (
    <Box
      component="div"
      sx={{
        backgroundColor: '#E6F3FA',
        padding: '12px 24px 12px',
        border: 'none',
        marginTop: '12px',
        fontFamily: 'ProximaNova, UtopiaStd',
        fontSize: '16px'
      }}>
      <Box component="p" sx={{ paddingBottom: -10, border: 'none', fontWeight: 700 }}>
        Instructions
      </Box>
      <Box component="p" sx={{ border: 'none' }}>
        Resident selected <strong>&quot;Offer to Change Lease End Date&quot;</strong>
        <ul>
          <li>Validate if the resident has submitted Early Termination payment within 24 hours.</li>
          <li>If yes, enter NTV in MRI. Enter Settlement Amount in MRI if paid.</li>
          <li>If no, select &quot;Accelerated Rent&quot; in MRI.</li>
        </ul>
      </Box>
    </Box>
  );
};

export default NoticeToVacateInstructions;
