/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// @ts-expect-error: Should expect image
import checkCircle from '../../../../assets/imgs/requestTypes/check_circle.png';
// @ts-expect-error: Should expect image
import closeCircle from '../../../../assets/imgs/requestTypes/close_circle.png';

import InfoToolTip from '../../../../shared/tooltip/InfoTooltip';
import formatForwardingAddress from '../../../../utils/formatForwardingAddress';
import NoticeToVacateInstructions from './NoticeToVacateInstructions';
import BlueText from './BlueText';
import TableCellTitle from './TableCellTitle';
import TableCellContent from './TableCellContent';
import {
  transferGreaterThan30Miles,
  transferWith30Miles,
  thirtyDaysGuarantee,
  offerToChangeLeaseEndDate,
  moveOutDateEqualLeaseEndDate,
  moveOutDateGreaterLeaseEndDate,
  showScreen,
  isGreenNoticeDays,
  acceleratedRent,
  moveOutDateLessThanLeaseEndDateNotLive,
  acceleratedRentWithPositiveFee,
  enforced30days,
  enforcedSubmittedDay
} from '../../../../utils/earlyTermDisplayFunctions';
import RedText from './RedText';

const NoticeToVacateInfo = ({ tableBody, idx, ntvFlags, isResolved }: any) => {
  const formattedAddress = formatForwardingAddress(tableBody?.forwardingAddress);

  return (
    <>
      <TableContainer key={idx} sx={{ marginBottom: '24px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellTitle>Submitted By</TableCellTitle>
              <TableCellTitle>Submitted On</TableCellTitle>
              <TableCellTitle>Move Out Date</TableCellTitle>
              <TableCellTitle>Lease End Date</TableCellTitle>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCellContent>{tableBody.submittedBy}</TableCellContent>
              <TableCellContent>{tableBody.submittedOn}</TableCellContent>
              <TableCellContent
                sx={{
                  border: 'none',
                  padding: '0 8px',
                  fontWeight:
                    !ntvFlags.isMoveOutDateGreaterThanLeaseEndDate &&
                    !ntvFlags.isMoveOutDateEqualLeaseEndDate
                      ? '700'
                      : 'normal',
                  color:
                    !ntvFlags.isMoveOutDateGreaterThanLeaseEndDate &&
                    !ntvFlags.isMoveOutDateEqualLeaseEndDate
                      ? '#F5222D'
                      : '#31343A'
                }}>
                {tableBody.moveOutDate}
              </TableCellContent>
              <TableCellContent sx={{ border: 'none', padding: '0 8px' }}>
                {tableBody.leaseEndDate}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellTitle sx={{ padding: '24px 8px 0' }}>
                Primary Reason for Moving Out
              </TableCellTitle>
              <TableCellTitle sx={{ padding: '24px 8px 0' }}>
                Residence Type You Will Be Moving Into
              </TableCellTitle>
              {showScreen(
                [acceleratedRent(ntvFlags)],
                !acceleratedRentWithPositiveFee(ntvFlags)
              ) && (
                <TableCellTitle sx={{ padding: '24px 8px 0' }}>
                  Number of Days Notice
                </TableCellTitle>
              )}
            </TableRow>
            <TableRow>
              <TableCellContent>{tableBody?.primaryReasonMovingOut}</TableCellContent>
              <TableCellContent>{tableBody?.residentType}</TableCellContent>
              {showScreen(
                [acceleratedRent(ntvFlags)],
                !acceleratedRentWithPositiveFee(ntvFlags)
              ) && (
                <TableCellContent>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {tableBody.daysNotice}{' '}
                    <div>
                      {isGreenNoticeDays(ntvFlags) ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div
                            style={{ paddingLeft: '5px', display: 'flex', alignItems: 'center' }}>
                            <InfoToolTip
                              position={'bottom'}
                              icon={checkCircle}
                              widthIcon={13}
                              text={
                                <div style={{ color: '#31343A', fontWeight: 'bold' }}>
                                  Required days notice was met
                                </div>
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            style={{ paddingLeft: '5px', display: 'flex', alignItems: 'center' }}>
                            <InfoToolTip
                              position={'bottom'}
                              icon={closeCircle}
                              widthIcon={13}
                              text={
                                <div style={{ color: '#31343A', fontWeight: 'bold' }}>
                                  Required days notice was not met
                                </div>
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </TableCellContent>
              )}
            </TableRow>
            <TableRow>
              {showScreen(
                [
                  transferWith30Miles(ntvFlags),
                  transferGreaterThan30Miles(ntvFlags),
                  moveOutDateGreaterLeaseEndDate(ntvFlags),
                  moveOutDateEqualLeaseEndDate(ntvFlags)
                ],
                offerToChangeLeaseEndDate(ntvFlags) || acceleratedRent(ntvFlags)
              ) && (
                <TableCellTitle sx={{ padding: '24px 8px 0' }}>
                  Resident Selection for Early Move Out:
                </TableCellTitle>
              )}

              {showScreen(
                [
                  transferWith30Miles(ntvFlags),
                  transferGreaterThan30Miles(ntvFlags),
                  moveOutDateGreaterLeaseEndDate(ntvFlags),
                  moveOutDateEqualLeaseEndDate(ntvFlags)
                ],
                offerToChangeLeaseEndDate(ntvFlags) || acceleratedRent(ntvFlags)
              ) && <TableCellTitle sx={{ padding: '24px 8px 0' }}>Charge:</TableCellTitle>}
              {showScreen(
                [
                  transferWith30Miles(ntvFlags),
                  transferGreaterThan30Miles(ntvFlags),
                  moveOutDateGreaterLeaseEndDate(ntvFlags),
                  moveOutDateEqualLeaseEndDate(ntvFlags)
                ],
                offerToChangeLeaseEndDate(ntvFlags)
              ) && <TableCellTitle sx={{ padding: '24px 8px 0' }}>Charge Type:</TableCellTitle>}
            </TableRow>
            <TableRow>
              {showScreen(
                [
                  transferWith30Miles(ntvFlags),
                  transferGreaterThan30Miles(ntvFlags),
                  moveOutDateGreaterLeaseEndDate(ntvFlags),
                  moveOutDateEqualLeaseEndDate(ntvFlags)
                ],
                offerToChangeLeaseEndDate(ntvFlags) || acceleratedRent(ntvFlags)
              ) && (
                <TableCellContent>
                  {acceleratedRent(ntvFlags)
                    ? 'Remain Rent Responsible'
                    : 'Offer to Change Lease End Date'}
                </TableCellContent>
              )}

              {showScreen(
                [
                  transferWith30Miles(ntvFlags),
                  transferGreaterThan30Miles(ntvFlags),
                  moveOutDateGreaterLeaseEndDate(ntvFlags),
                  moveOutDateEqualLeaseEndDate(ntvFlags)
                ],
                offerToChangeLeaseEndDate(ntvFlags) || acceleratedRent(ntvFlags)
              ) && (
                <TableCellContent
                  sx={{
                    fontWeight: acceleratedRent(ntvFlags) ? 'normal' : '700',
                    color: acceleratedRent(ntvFlags) ? '#31343A' : '#0288D1'
                  }}>
                  {acceleratedRent(ntvFlags) ? 'Accelerated Rent' : ntvFlags?.earlyTerminationFee}
                </TableCellContent>
              )}
              {showScreen(
                [
                  transferWith30Miles(ntvFlags),
                  transferGreaterThan30Miles(ntvFlags),
                  moveOutDateGreaterLeaseEndDate(ntvFlags),
                  moveOutDateEqualLeaseEndDate(ntvFlags)
                ],
                offerToChangeLeaseEndDate(ntvFlags)
              ) && <TableCellContent>Settlement Amount</TableCellContent>}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {thirtyDaysGuarantee(ntvFlags) && <BlueText text="30 Day Guarantee" />}
      {[!thirtyDaysGuarantee(ntvFlags), transferWith30Miles(ntvFlags)].every(
        (item) => item === true
      ) && <BlueText text="Transfer Within 30 miles" />}
      {[!thirtyDaysGuarantee(ntvFlags), transferGreaterThan30Miles(ntvFlags)].every(
        (item) => item === true
      ) && <BlueText text="Transfer Greater Than 30 Miles" />}

      <TableContainer key={idx} sx={{ marginBottom: '24px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCellTitle>Forwarding Address</TableCellTitle>
            </TableRow>
            <TableRow>
              <TableCellContent>{formattedAddress}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellTitle sx={{ padding: '24px 8px 0' }}>Feedback / Comments</TableCellTitle>
            </TableRow>
            <TableRow>
              <TableCellContent>{tableBody.feedback}</TableCellContent>
            </TableRow>
            {offerToChangeLeaseEndDate(ntvFlags) && (
              <TableRow>
                <NoticeToVacateInstructions />
              </TableRow>
            )}

            {[
              !thirtyDaysGuarantee(ntvFlags),
              !transferGreaterThan30Miles(ntvFlags),
              !transferWith30Miles(ntvFlags),
              moveOutDateLessThanLeaseEndDateNotLive(ntvFlags)
            ].every((item) => item === true) && (
              <TableRow>
                <RedText sx={{ padding: '24px 8px 0' }}>
                  Identify if a “Settlement” charge can be offered. If so, contact resident to
                  discuss. If not, proceed to enter NTV as “Accelerated Rent”.
                </RedText>
              </TableRow>
            )}

            {enforced30days(ntvFlags) && (
              <TableRow>
                <RedText sx={{ padding: '24px 8px 0' }}>
                  ALERT: 30 Day Notice Not Met if NTV Entered with Settlement Amount.
                </RedText>
                <RedText>
                  Create task in Salesforce to set the IAR bill-through date to{' '}
                  {enforcedSubmittedDay(tableBody.submittedOn)}. Assign task to a community
                  associate.
                </RedText>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isResolved && (
        <TableContainer key={idx} sx={{ marginBottom: '24px' }}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCellTitle sx={{ padding: '24px 8px 0' }}>MRI Status</TableCellTitle>
              </TableRow>
              <TableRow>
                <TableCellContent>{tableBody.statusMRI}</TableCellContent>
              </TableRow>
              <TableRow>
                <TableCellTitle sx={{ padding: '24px 8px 0' }}>Resolved By</TableCellTitle>
                <TableCellTitle align="left" sx={{ padding: '24px 8px 0' }}>
                  Date Time Resolved
                </TableCellTitle>
                <TableCellTitle sx={{ padding: '24px 8px 0' }}>&nbsp;</TableCellTitle>
                <TableCellTitle sx={{ padding: '24px 8px 0' }}>&nbsp;</TableCellTitle>
              </TableRow>
              <TableRow>
                <TableCellContent>{tableBody.resolvedBy}</TableCellContent>
                <TableCellContent align="left">{tableBody.dateTimeResolved}</TableCellContent>
                <TableCellContent sx={{ width: '20%' }}>&nbsp;</TableCellContent>
                <TableCellContent sx={{ width: '20%' }}>&nbsp;</TableCellContent>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default NoticeToVacateInfo;
