/* eslint-disable @typescript-eslint/no-explicit-any */
export interface FilterCheckboxProps {
  label?: Label;
  checkedItems?: any;
  handleChange: any;
  generateLeaseForms?: boolean;
}

interface Label {
  displayName: string;
  name: string;
  value: string;
  sublabel?: string;
}

export interface DialogContainerFiltersProps {
  checkedItems: any;
  handleChange: (e: any) => void;
  filterCheckboxes: any;
  generateLeaseForms?: boolean;
}

export const filterRequestTypes = (
  filters: string | undefined,
  exception: (item: string) => boolean
): string => {
  return (
    filters
      ?.split(',')
      .map((item) => item.trim())
      .filter((item) => item !== '')
      .filter(exception)
      .join(',') || ''
  );
};
