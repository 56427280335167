/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';

// @ts-ignore
import LabelStatus from '../../../../shared/labelStatus/LabelStatus';
import Collapse from '@mui/material/Collapse';

import { StatusLabelProps } from '../../../../modules/dashboard/residents/domain/ResidentTable';
import { TableStyles } from './styles';

const StatusLabel: FC<StatusLabelProps> = ({ item, idx, isRowExpanded }) => {
  const [label, setLabel] = useState('');
  const [subLabel, setSubLabel] = useState('');

  const assignLabel = () => {
    switch (item?.requestType as string) {
      case 'notice-to-vacate-offer':
        setLabel('NTV Requested');
        setSubLabel('With Renewal Offer');
        break;
      case 'notice-to-vacate-no-offer':
        setLabel('NTV Requested');
        setSubLabel('No Renewal Offer');
        break;
      default:
        restoreLabels();
        break;
    }
  };

  const restoreLabels = () => {
    setLabel('');
    setSubLabel('');
  };

  const isMoveOutRequest =
    item?.helpRequestTypes && !item.helpRequestTypes.includes('Process Move Out');

  const isNoticeToVacate =
    item?.requestType === 'notice-to-vacate-no-offer' ||
    item?.requestType === 'notice-to-vacate-offer';

  const createLabel = () => {
    if (isMoveOutRequest && isNoticeToVacate) {
      assignLabel();
    } else {
      restoreLabels();
    }
  };

  useEffect(() => {
    createLabel();
  }, [item.requestType]);

  return (
    <>
      {label !== '' && item?.helpRequestTypes && (
        <div style={{ paddingBottom: '5px' }}>
          <LabelStatus label={label} subLabel={subLabel} />
        </div>
      )}
      {label === '' && (item?.helpRequestTypes ? item?.helpRequestTypes?.length > 0 : false) && (
        <div style={{ paddingBottom: '5px' }}>
          <LabelStatus label={item?.helpRequestTypes?.[0]} subLabel={undefined} />
        </div>
      )}
      <Collapse in={isRowExpanded(idx)}>
        <div style={TableStyles.LabelContainer}>
          {item?.helpRequestTypes?.map(
            (type: string[] | string, i: number) =>
              (label === '' ? i > 0 : i >= 0) && (
                <LabelStatus key={type} label={type} subLabel={undefined} />
              )
          )}
        </div>
      </Collapse>
    </>
  );
};

export default StatusLabel;
